@import 'styles/variables';
@import 'bootstrap/scss/mixins';

.summary {
  border: 1px solid $nikon-checkout-item-border;
  margin: 0;
  margin-bottom: 15px;
  padding: 12.5px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 7.5px;
}
