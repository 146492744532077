@import 'styles/variables';
@import 'bootstrap/scss/mixins';

.summary {
  background-color: $nikon-checkout-light-bg;
  margin: 0;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  gap: 7.5px;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    gap: 35px;
  }
}
