@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.modal_header {
  & > button {
    background-size: 20px;
  }
}

.modal_dialog {
  @include media-breakpoint-up(md) {
    width: 440px;
  }
}
