@import 'styles/variables';
@import 'bootstrap/scss/vendor/rfs';
@import 'bootstrap/scss/mixins/breakpoints';

.listItem {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.tileBody {
  padding: 15px;
}

.hoverBody {
  &:hover {
    border: 2px solid;
    padding: 13px;
  }
}

.borderAdd {
  border: 2px solid;
}

.price-container {
  margin-bottom: rfs-value(15px);
  font: rfs-value(18px)/rfs-value(27px) $font-family-base;

  & .price {
    font-weight: $font-weight-bold;
  }


  & .oldPrice {
    color: $nikon-dark-grey;
    text-decoration: line-through;
    font-size: rfs-value(14px);
  }

  & .save-money {
    display: block;
    margin-top: rfs-value(10px);
    font-weight: $font-weight-bold;
    color: $nikon-blood-orange;
  }

  @include media-breakpoint-down(sm) {
    font: rfs-value(16px)/rfs-value(25px);

    & .oldPrice {
      font-size: rfs-value(12px);
    }

    & .save-money {
      color: $nikon-blood-orange;
    }
  }
}

.addToCart {
  margin: 0 auto;
  text-align: center;


  @include media-breakpoint-down(sm) {
    :global(.btn-yellow) {
      font-size: rfs-value(14px);
    }
  }
}

.max-quantity-meet {
  line-height: rfs-value(22px);
  font-size: rfs-value(14px);
  font-style: italic;
  color: $nikon-dark-grey;
}

.add-to-cart-error {
  font-size: rfs-value(14px);
  line-height: rfs-value(20px);
  color : $nikon-red;
}
