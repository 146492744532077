// ideally we would only import pieces of bootstrap that we use. however, sposto might need any
// or all components, so we need to include the entire bootstrap css

// include any default variable overrides here (functions are not available at this point)

@import 'fonts';
@import 'icons-legacy';
@import 'variables';
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins/breakpoints';

// global styles
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricPrecision;
}

@include media-breakpoint-down(sm) {
  .is-touch input {
    font-size: rfs-value(16px) !important;
  }
}

strong,
.strong {
  font-weight: $font-weight-bold;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 700;
}

a {
  color: $nikon-link-bold;

  &:hover {
    color: $nikon-link-bold-hover;
  }
}

.small {
  font-size: rfs-value(12px);
  line-height: 1.167;
}

//region Buttons
.btn {
  letter-spacing: 1px;
}

.btn-yellow {
  color: $nikon-black !important;
  background: $nikon-yellow;
  border-color: $nikon-yellow;
  border-radius: var(--bs-border-radius-pill) !important;
  text-transform: uppercase;
  text-decoration: none;
  font-size: rfs-value(16px);
  font-weight: $font-weight-semibold;
  line-height: rfs-value(22px);
  padding: rfs-value(10px 21px 9px);

  &:active,
  &:focus,
  &:hover {
    color: $nikon-black !important;
    background: $nikon-yellow-hover;
    border-color: $nikon-yellow-hover;
  }

  &:disabled {
    @extend .btn-disabled;
  }
}

.btn-green {
  @extend .btn-yellow;

  background: #337722;
  border-color: #337722;
  color: $nikon-white !important;

  &:active,
  &:focus,
  &:hover {
    color: $nikon-white !important;
    background: #337722;
    border-color: #337722;
  }

  &:disabled {
    @extend .btn-disabled;
  }
}

.btn-black {
  @extend .btn-yellow;

  background: $nikon-black;
  border-color: $nikon-black;
  color: $nikon-white-color !important;
  letter-spacing: normal;

  &:active,
  &:focus,
  &:hover {
    color: $nikon-white-color !important;
    background: $nikon-black;
    border-color: $nikon-black;
  }

  &:disabled {
    @extend .btn-disabled;
  }
}

.btn-gray {
  @extend .btn-yellow;

  background: $nikon-light-grey;
  border-color: $nikon-light-grey;
  color: $nikon-black !important;
  letter-spacing: normal;
  border-radius: 0.5rem !important;

  &:active,
  &:focus,
  &:hover {
    color: $nikon-white-color !important;
    background: $nikon-black;
    border-color: $nikon-black;
  }
}

.btn-black-outline {
  @extend .btn-black;

  background: $nikon-white;
  border: 1px solid $nikon-black;
  color: $nikon-black !important;

  &:active,
  &:focus,
  &:hover {
    color: $nikon-white-color !important;
    background: $nikon-black;
    border-color: $nikon-black;
  }

  &:disabled {
    @extend .btn-disabled;
  }
}

.btn-disabled {
  @extend .btn-yellow;
  background: $nikon-light-grey !important;
  border-color: $nikon-light-grey;
  cursor: not-allowed;
  opacity: 1;

  &:active,
  &:focus,
  &:hover {
    background: $nikon-light-grey;
    border-color: $nikon-light-grey;
  }
}

.btn-black-yellow {
  @extend .btn-black;

  &:active,
  &:focus,
  &:hover {
    color: $nikon-black !important;
    background: $nikon-yellow-hover !important;
    border-color: $nikon-yellow-hover !important;
  }

  &:disabled {
    @extend .btn-disabled;
  }
}

.btn-secondary {
  --bs-btn-bg: #000;
  --bs-btn-border-color: #000;
  --bs-btn-hover-bg: #000;
  --bs-btn-hover-border-color: #000;
}

.nk-btn-smooth-rounded {
  border-radius: 10px !important;
}

.nk-btn-rounded {
  border: 1px solid transparent;
  border-radius: 90px;
  cursor: pointer;
  display: inline-block;
  font-size: rfs-value(12px);
  font-weight: 700;
  line-height: 1.2;
  min-width: 140px;
  padding: 10px 32px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  text-transform: uppercase;
  -webkit-transition: background 0.15s ease-out, border-color 0.15s ease-out;
  transition: background 0.15s ease-out, border-color 0.15s ease-out;
  vertical-align: middle;

  &.nk-btn-primary {
    background-color: $nikon-yellow;
    border-color: $nikon-yellow;
    color: $nikon-black;

    &:hover {
      cursor: pointer;
      background-color: $nikon-yellow-hover;
      border-color: $nikon-yellow-hover;
    }
  }

  @include media-breakpoint-up(md) {
    font-size: rfs-value(14px);
    padding: 15px 52px;
  }
}

.btn.is-loading,
button.is-loading {
  position: relative;
  color: transparent !important;

  .loading-icon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.is-loading-with-disabled {
  @extend .is-loading;

  &:disabled {
    background: $nikon-yellow !important;
    border-color: $nikon-yellow !important;
  }
}
//endregion Buttons

//region Bootstrap Overrides
.form-control {
  //--bs-border-radius: 0;
  --bs-border-color: #000;
  padding: 12px 10px 9px;
  font-size: rfs-value(14px);
  font-weight: $font-weight-normal;
  line-height: rfs-value(20px);
  color: $nikon-text-dark-grey;

  &:focus {
    outline: 5px auto $nikon-black;
  }
  &.is-invalid {
    &:focus {
      outline: none;
    }
  }
  &.error {
    outline-color: $form-feedback-invalid-color !important;
    border-color: $form-feedback-invalid-color !important;
  }
}

.form-label {
  margin: 0 0 4px;
  font-size: rfs-value(14px);
  font-weight: $font-weight-normal;
  line-height: rfs-value(22px);
}

.popover-body {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

#productItem-tooltip-popoover {
  border-radius: 6px;

  #productItem-tooltip-popoover-body {
    .bs-popover-end > .popover-arrow {
      display: none !important;
    }

    .popover {
      border: none !important;
    }
  }
}
//endregion Bootstrap Overrides

//region Special inputs and elements
.customFormCheckbox {
  position: relative;
  padding-left: 0;
  margin: 0;
  font-size: rfs-value(14px);
  line-height: rfs-value(24px);

  input {
    position: absolute;
    left: -9999px;
  }

  label {
    cursor: pointer;
  }

  label::before {
    content: '';
    min-width: 16px;
    width: 16px;
    height: 16px;
    display: inline-block;
    border: 1px solid $nikon-border-light-grey;
    border-radius: 4px;
    vertical-align: text-bottom;
    margin-right: 10px;
    background: $nikon-white;
  }

  input:checked ~ label::after {
    content: '';
    width: 18px;
    height: 14px;
    background: url(/icons/checkmark.svg) center no-repeat;
    background-size: contain;
    position: absolute;
    top: 4px;
    left: 1px;
  }

  &:focus label::before,
  &:focus-within label::before {
    outline: 5px auto $nikon-black;
  }
}

.questionIcon {
  display: inline-block;
  padding: 4px 0;
  width: 24px;
  height: 24px;
  border: 2px solid $nikon-black;
  border-radius: 50%;
  font-size: rfs-value(15px);
  line-height: rfs-value(15px);
  font-weight: $font-weight-bold;
  text-align: center;
  cursor: pointer;
}
//endregion Special inputs and elements

.no-margin {
  margin: 0 !important;
}

.spacer-xl {
  margin-bottom: 30px !important;
}

.max-970 {
  max-width: 970px;
}

.h2 {
  font-size: calc(40px + (55 - 40) * ((100vw - 768px) / (1440 - 768)));
  line-height: 0.964;

  @include media-breakpoint-up(xxl) {
    font-size: 3.438em;
  }
}

.h5 {
  font-size: 1.125em;
  line-height: 1;
}

.collapse {
  visibility: unset;
}

.padding-wide {
  padding-left: 30px;
  padding-right: 30px;
}

/* hide contentstack live preview button */
.cslp-tooltip {
  display: none !important;
}

/* default block animation */
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate {
  opacity: 0;

  &.in-view {
    animation: slideUp 0.8s ease 0.1s 1 normal forwards;
  }

  @media (prefers-reduced-motion) {
    opacity: 1;
    animation: none !important;
  }
}

/* default block spacing */
.content-blocks {
  margin-top: -60px;
  margin-bottom: -60px;

  & > .content-block {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .nikon-homepage & {
    margin-top: -15px;
    margin-bottom: -15px;

    & > .content-block {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
}

.no-padding {
  padding: 0 !important;
}

.full-width {
  max-width: 100%;
}

.panel-container {
  & + & {
    margin-top: 15px;
  }

  @include media-breakpoint-up(md) {
    margin-top: 60px;
  }
}

/* dropdowns */
.dropdown-toggle {
  &:after {
    border: solid rgba(0, 0, 0, 0.65);
    border-width: 0 2px 2px 0;
    display: inline-block;
    margin-left: rfs-value(8px);
    padding: rfs-value(3px);
    transform: rotate(45deg);
    vertical-align: 3px;
  }
  &.show:after {
    vertical-align: -1px;
    transform: rotate(-135deg);
  }
}

/* tabs */
.tab-pane {
  @include media-breakpoint-up(md) {
    padding-bottom: rfs-value(60px);
  }

  h2 {
    font-size: rfs-value(36px);
    font-weight: 600;
    line-height: 1.15em;
    margin: rfs-value(60px auto 0);
    padding: rfs-value(0 15px 60px);
    text-align: center;
    width: 100%;

    @include media-breakpoint-up(md) {
      font-size: rfs-value(48px);
    }
  }
}

.productsTabPane h2 {
  @include media-breakpoint-up(md) {
    padding-bottom: 25px;
  }
}

.tab-content {
  a:focus,
  button:focus,
  select:focus {
    -webkit-box-shadow: 0 0 0 2px #fff, 0 0 0 4px #000, 0 0 4px 8px #fff;
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #000, 0 0 4px 8px #fff;
    outline: 2px solid transparent;
  }

  input:focus {
    -webkit-box-shadow: 0 0 0 2px #fff, 0 0 0 4px #000, 0 0 4px 8px #fff !important;
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #000, 0 0 4px 8px #fff !important;
    outline: 2px solid transparent !important;
  }
}

/* Image carousel css */
main .carousel {
  &.has-controls {
    display: flex;
    flex-wrap: wrap;
    gap: 30px 15px;
    justify-content: center;
  }
  &.carousel-arrows-on-sides {
    width: calc(100% - 170px);
    max-width: 1170px;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    &.carousel-full-width {
      width: 100%;
      max-width: unset;

      &.white-arrows {
        & .carousel-control-prev,
        .carousel-control-next {
          background-color: #fff;
          opacity: 1 !important;

          &:hover > span {
            filter: none;
          }

          &:focus > span {
            filter: none;
          }
        }
      }

      & .carousel-control-prev {
        position: absolute !important;
        top: calc(50% - 22.5px) !important;
        left: 60px !important;

        @include media-breakpoint-down(md) {
          position: relative !important;
          top: unset !important;
          left: unset !important;
        }
      }

      & .carousel-control-next {
        position: absolute !important;
        top: calc(50% - 22.5px) !important;
        right: 60px !important;

        @include media-breakpoint-down(md) {
          position: relative !important;
          top: unset !important;
          right: unset !important;
        }
      }
    }

    & .carousel-control-prev {
      position: absolute !important;
      top: calc(50% - 22.5px) !important;
      left: -60px !important;

      @include media-breakpoint-down(md) {
        position: relative !important;
        top: unset !important;
        left: unset !important;
      }
    }

    & .carousel-control-next {
      position: absolute !important;
      top: calc(50% - 22.5px) !important;
      right: -60px !important;

      @include media-breakpoint-down(md) {
        position: relative !important;
        top: unset !important;
        right: unset !important;
      }
    }
  }
  .carousel-inner {
    width: 100%;
  }
  .carousel-item {
    &.active {
      display: flex;
      flex-wrap: wrap;
    }

    img {
      position: relative;
    }
  }
  .carousel-caption {
    position: relative;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
    color: black;
    font-family: $font-family-base;
    font-size: rfs-value(14px);
    text-align: center;
    width: 100%;
    padding: rfs-value(30px 15px 0);
    max-width: 992px;
    margin: 0 auto;
  }
  .carousel-control-prev,
  .carousel-control-next {
    position: relative;
    border-radius: 50%;
    background: #d3d3d3;
    width: rfs-value(45px);
    height: rfs-value(45px);
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;

    &:hover,
    &:active,
    &:focus {
      background: #a09f9f;

      .carousel-control-next-icon,
      .carousel-control-prev-icon {
        filter: invert(1);
      }
    }
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    position: relative;
    width: rfs-value(22px);
    height: rfs-value(22px);
  }

  .carousel-control-prev-icon {
    left: -1px;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
  }
  .carousel-control-next-icon {
    right: -1px;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
  }
}

// R&R Css
.tt-w-submission-modal {
  svg {
    display: inline-block !important;
  }
}

// Lightbox Modal
body {
  &:has(> .lg-container.lg-show) {
    overflow: hidden;
  }

  .lg-container {
    *::after,
    *::before {
      text-rendering: optimizeLegibility;
      font-feature-settings: 'liga';
      -webkit-font-smoothing: antialiased;
      font-family: $icon-font;
      font-style: normal;
      font-weight: 400;
      text-decoration: none;
      white-space: nowrap;

      font-size: 18px;
      right: 8px;
      top: 14px;
    }

    .lg-toolbar {
      .lg-icon {
        height: 44px;
        width: 44px;
      }
      .lg-download {
        display: none;
      }
      .lg-zoom-in {
        &:after {
          content: '' !important;
        }
      }
      .lg-zoom-out {
        &:after {
          content: '';
        }
      }
      .lg-fullscreen {
        &:after {
          content: '⤢';
        }
      }
      .lg-close {
        &:after {
          content: '␡';
        }
      }
    }

    .lg-content {
      .lg-prev,
      .lg-next {
        background: #000;
        border: 1px solid #fff;
        border-radius: 2px;
        cursor: pointer;
        font-size: inherit;
        text-decoration: none;
        height: 40px;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: -20px;
        padding-top: 9px;
        top: 50%;
        width: 40px;

        &:after,
        &:before {
          font-size: 12px;
        }
      }

      .lg-prev {
        &:after {
          content: '◅';
        }
      }
      .lg-next {
        &:before {
          content: '▻';
        }
      }

      .lg-video-cont {
        position: relative;
        width: 100%;

        iframe {
          width: 80%;
          height: 80%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .lg-components {
      display: none;
    }
  }
}

// btn variants
.btn-secondary-black {
  border: 1px solid transparent;
  background: $body-color;
  color: $nikon-bg-color;

  &:first-child,
  &:last-of-type {
    border: 1px solid transparent;
    background: $body-color;
    color: $nikon-bg-color;

    &:hover {
      background-color: $nikon-dark-grey;
    }

    &:focus,
    &:active {
      outline: 2px solid $body-color;
      border: 2px solid $nikon-bg-color;
      box-shadow: 0 0 0 2px $nikon-bg-color;
      background-color: $nikon-dark-grey;
    }
  }

  &:hover {
    color: $nikon-bg-color;
    background-color: $nikon-dark-grey;
  }

  &:focus,
  &:active {
    color: $nikon-bg-color;
    outline: 2px solid $body-color;
    border: 2px solid $nikon-bg-color;
    box-shadow: 0 0 0 2px $nikon-bg-color;
    background-color: $nikon-dark-grey;
  }
}

.btn-secondary-white {
  background: $nikon-bg-color;
  border-color: $body-color;
  color: $body-color;

  &:first-child,
  &:last-of-type {
    background: $nikon-bg-color;
    border-color: $body-color;
    color: $body-color;
    &:hover,
    &:active,
    &:focus {
      background: $nikon-btn-white-bg-color;
      border-color: $body-color;
      color: $body-color;
    }
    &:active,
    &:focus {
      box-shadow: 0 0 0 2px $nikon-bg-color, 0 0 0 4px $body-color, 0 0 4px 8px $nikon-bg-color;
      outline: 2px transparent solid;
    }
  }

  &:hover,
  &:active,
  &:focus {
    background: $nikon-btn-white-bg-color;
    border-color: $body-color;
    color: $body-color;
  }
  &:active,
  &:focus {
    box-shadow: 0 0 0 2px $nikon-bg-color, 0 0 0 4px $body-color, 0 0 4px 8px $nikon-bg-color;
    outline: 2px transparent solid;
  }
}

.btn-secondary-yellow {
  background: $nikon-yellow;
  border-color: $nikon-yellow;
  color: $body-color;

  &:hover,
  &:active,
  &:focus {
    background: $nikon-yellow-hover;
    border-color: $nikon-yellow-hover;
    color: $body-color;
  }
  &:active,
  &:focus {
    box-shadow: 0 0 0 2px $nikon-bg-color, 0 0 0 4px $body-color, 0 0 4px 8px $nikon-bg-color;
    outline: 2px transparent solid;
  }

  &:first-child,
  &:last-of-type {
    background: $nikon-yellow;
    border-color: $nikon-yellow;
    color: $body-color;

    &:hover,
    &:active,
    &:focus {
      background: $nikon-yellow-hover;
      border-color: $nikon-yellow-hover;
      color: $body-color;
    }
    &:active,
    &:focus {
      box-shadow: 0 0 0 2px $nikon-bg-color, 0 0 0 4px $body-color, 0 0 4px 8px $nikon-bg-color;
      outline: 2px transparent solid;
    }
  }
}

div[role='alert'] {
  a {
    color: var(--bs-danger-text-emphasis);
    font-weight: bold;
  }
}

// modals
.modal-header {
  padding-bottom: 0;
}
.modal-title {
  font-weight: 700;
}
.modal-footer {
  padding-top: 0;
}

// paypal buttons
.paypal-buttons-disabled {
  opacity: 1 !important;

  &:hover {
    opacity: 0.7 !important;
  }
}
